<template>
    <div class="form-content">
        <div class="setting-box" v-for="(item,index) in adData" :key="`adData_${index}`">
            <div class="title-item">{{item.title}}
                <div class="button-box" v-if="index == 2" @click="addAge">添加年龄段</div>
            </div>
            <div class="item-column-content">
                <div class="column-item" v-for="(subItem,subIndex) in item.list" :key="`settingItem_${subIndex}`">
                    <div class="odd-content">
                        <div class="item-content-title">{{subItem.title}}</div>
                        <span class="del-btn" v-if="index == 2 && subIndex > 4"
                              @click="delAge(index,subIndex)">删除</span>
                    </div>
                    <div class="odd-content" v-if="index == 0">
                        <div class="item-title">活动时间</div>
                        <div class="odd-item">
                            <el-input-number class="item-input medium" ref="time_client_num_start"
                                             v-model="subItem.client_num_start"
                                             disabled
                                             :min="0"
                                             :max="60"
                                             :controls="false"
                                             :precision="0"></el-input-number>
                            <span class="input-text">分</span>
                            <el-input-number class="item-input medium" ref="time_client_num_end"
                                             v-model="subItem.client_num_end"
                                             disabled
                                             :min="0"
                                             :max="59"
                                             :controls="false"
                                             :precision="0"></el-input-number>
                            <span class="input-text">秒</span>
                        </div>
                    </div>
                    <div class="odd-content" v-if="index == 2">
                        <div class="item-title" v-if="subIndex == 0">年龄段</div>
                        <div class="odd-item">
                            <el-input-number class="item-input mini" :ref="`age_start_${index}_${subIndex}`"
                                             v-model="subItem.wave_range_start"
                                             :min="0"
                                             :max="100"
                                             :controls="false"
                                             :precision="0">
                            </el-input-number>
                            <span class="input-text">~</span>
                            <el-input-number class="item-input mini" :ref="`age_end_${index}_${subIndex}`"
                                             v-model="subItem.wave_range_end"
                                             :min="0"
                                             :max="100"
                                             :controls="false"
                                             :precision="0">
                            </el-input-number>
                        </div>
                    </div>
                    <div class="odd-content">
                        <div class="item-title" v-if="subIndex == 0">占比</div>
                        <div class="odd-item">
                            <el-input-number class="item-input max" :ref="`ratio_${index}_${subIndex}`"
                                             v-model="subItem.proportion"
                                             :min="1"
                                             :max="100"
                                             :controls="false"
                                             :precision="0"></el-input-number>
                            <span class="input-text">%</span>
                        </div>
                    </div>
                    <div class="odd-content" v-if="!item.hiddenWave">
                        <div class="item-title" v-if="subIndex == 0">波动范围</div>
                        <div class="odd-item">
                            <el-input-number class="item-input mini" :ref="`wave_range_start_${index}_${subIndex}`"
                                             v-model="subItem.wave_range_start"
                                             :min="-99"
                                             :max="100"
                                             :controls="false"
                                             :precision="0">
                            </el-input-number>
                            <span class="input-text">%</span>
                            <span class="input-text" style="margin-left: 0;">~</span>
                            <el-input-number class="item-input mini" :ref="`wave_range_end_${index}_${subIndex}`"
                                             v-model="subItem.wave_range_end"
                                             :min="-99"
                                             :max="100"
                                             :controls="false"
                                             :precision="0">
                            </el-input-number>
                            <span class="input-text">%</span>
                        </div>
                    </div>
                    <div class="odd-content" v-if="subIndex == 0 && !item.hiddenTrans">
                        <div class="item-title" v-if="index == 4">转化率</div>
                        <div class="item-title" v-else>第一名转化率</div>
                        <div class="odd-item">
                            <el-input-number class="item-input max" :ref="`trans_rate__${index}_${subIndex}`"
                                             v-model="subItem.trans_rate"
                                             :min="0.01"
                                             :max="10"
                                             :controls="false"
                                             :precision="2"></el-input-number>
                            <span class="input-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "addLaunchData",
        props: ['stepOneData', 'stepTwoData'],
        data() {
            return {
                adData: [
                    {
                        title: '活动时间',
                        hiddenTrans: true,
                        skipRatio: true,
                        list: [
                            {
                                client_num_start: void 0,
                                client_num_end: void 0,
                                proportion: void 0,
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                            }
                        ],
                    },
                    {
                        title: '地区设置',
                        hiddenWave: true,
                        calcRatio: true,
                        list: [
                            {
                                title: '一线城市',
                                proportion: void 0,
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                                trans_rate: void 0,
                            },
                            {
                                title: '新一线城市',
                                proportion: void 0,
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                            },
                            {
                                title: '二线城市',
                                proportion: void 0,
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                            },
                            {
                                title: '三线城市',
                                proportion: void 0,
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                            },
                            {
                                title: '四线城市',
                                proportion: void 0,
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                            },
                            {
                                title: '五线城市',
                                proportion: void 0,
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                            },
                        ],
                    },
                    {
                        title: '年龄设置',
                        hiddenWave: true,
                        calcRatio: true,
                        list: [
                            {
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                                proportion: void 0,
                                trans_rate: void 0,
                            },
                            {
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                                proportion: void 0,
                            },
                            {
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                                proportion: void 0,
                            },
                            {
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                                proportion: void 0,
                            },
                            {
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                                proportion: void 0,
                            },
                            {
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                                proportion: void 0,
                            },
                        ],
                    },
                    {
                        title: '性别设置',
                        hiddenWave: true,
                        calcRatio: true,
                        list: [
                            {
                                title: '男',
                                proportion: void 0,
                                trans_rate: void 0,
                            },
                            {
                                title: '女',
                                proportion: void 0,
                            },
                        ],
                    },
                    {
                        title: '行为兴趣设置',
                        skipRatio: true,
                        list: [
                            {
                                title: '行为兴趣',
                                proportion: void 0,
                                trans_rate: void 0,
                                wave_range_start: void 0,
                                wave_range_end: void 0,
                            },
                        ],
                    },
                    {
                        title: '网络设置',
                        calcRatio: true,
                        hiddenWave: true,
                        list: [
                            {
                                title: 'WIFI',
                                proportion: void 0,
                                trans_rate: void 0,
                            },
                            {
                                title: '2G',
                                proportion: void 0,
                            },
                            {
                                title: '3G',
                                proportion: void 0,
                            },
                            {
                                title: '4G',
                                proportion: void 0,
                            },
                        ],
                    },
                    {
                        title: '设备设置',
                        calcRatio: true,
                        hiddenWave: true,
                        list: [
                            {
                                title: 'ios',
                                proportion: void 0,
                                trans_rate: void 0,
                            },
                            {
                                title: '安卓',
                                proportion: void 0,
                            },
                        ],
                    },
                    {
                        title: '更多人群设置',
                        hiddenWave: true,
                        calcRatio: true,
                        list: [
                            {
                                title: '高订单取消/退货人群',
                                proportion: void 0,
                                trans_rate: void 0,
                            },
                            {
                                title: '高关注度人群',
                                proportion: void 0,
                            },
                            {
                                title: '促销敏感人群',
                                proportion: void 0,
                            },
                            {
                                title: '高活跃人群',
                                proportion: void 0,
                            }, {
                                title: '抖音号粉丝',
                                proportion: void 0,
                            },

                        ]
                    }
                ],
            }
        },
        mounted() {
            if (this.$route.query.id) {
                this.adData = this.stepTwoData
            } else if (localStorage.getItem('stepTwoData')) {
                // let adLaunchData = JSON.parse(localStorage.getItem('adLaunchData'))
                // if (adLaunchData.stepTwoData) {
                //     this.adData = adLaunchData.stepTwoData
                // }
                this.adData = JSON.parse(localStorage.getItem('stepTwoData'))
            }
            this.calcTime();
        },
        methods: {
            calcTime() {
                let min = 0, sec = 0, time = Number(this.stepOneData.total_time)
                min = parseInt(time / 60)
                sec = time - parseInt(min * 60)
                this.adData[0].list[0].client_num_start = min
                this.adData[0].list[0].client_num_end = sec
            },
            addAge() {
                let obj = {
                    wave_range_start: void 0,
                    wave_range_end: void 0,
                    proportion: void 0,
                }
                this.adData[2].list.push(obj)
            },
            checkStatus() {
                let isErrNum = 0, isErrOrder = 0, isErrAge = 0, isSum = 0, sumArr = [];
                this.adData.forEach((item, index) => {
                    let sum = 0;
                    item.list.forEach((subItem, subIndex) => {
                        if (index == 0) {
                            if (JSON.stringify(subItem.client_num_start) == '') {
                                isErrNum = 1
                                this.$refs.time_client_num_start[0].$el.classList.add('isError')
                            } else {
                                this.$refs.time_client_num_start[0].$el.classList.remove('isError')
                            }
                            if (JSON.stringify(subItem.client_num_end) == '') {
                                isErrNum = 1
                                this.$refs.time_client_num_end[0].$el.classList.add('isError')
                            } else {
                                this.$refs.time_client_num_end[0].$el.classList.remove('isError')
                            }
                        }
                        if (!subItem.proportion) {
                            isErrNum = 1
                            this.$refs[`ratio_${index}_${subIndex}`][0].$el.classList.add('isError')
                        } else {
                            this.$refs[`ratio_${index}_${subIndex}`][0].$el.classList.remove('isError')
                        }
                        if (item.calcRatio) {
                            sum += subItem.proportion ? subItem.proportion : 0
                        }
                        if (!item.hiddenTrans && subIndex == 0) {
                            if (!subItem.trans_rate) {
                                isErrNum = 1
                                this.$refs[`trans_rate__${index}_${subIndex}`][0].$el.classList.add('isError')
                            } else {
                                this.$refs[`trans_rate__${index}_${subIndex}`][0].$el.classList.remove('isError')
                            }
                        }
                        if (!item.hiddenWave) {
                            if (JSON.stringify(subItem.wave_range_start) == '') {
                                isErrNum = 1
                                this.$refs[`wave_range_start_${index}_${subIndex}`][0].$el.classList.add('isError')
                            } else {
                                this.$refs[`wave_range_start_${index}_${subIndex}`][0].$el.classList.remove('isError')
                            }
                            if (JSON.stringify(subItem.wave_range_end) == '') {
                                isErrNum = 1
                                this.$refs[`wave_range_end_${index}_${subIndex}`][0].$el.classList.add('isError')
                            } else {
                                this.$refs[`wave_range_end_${index}_${subIndex}`][0].$el.classList.remove('isError')
                            }

                            if (subItem.wave_range_end <= subItem.wave_range_start) {
                                isErrOrder = 1
                                this.$refs[`wave_range_end_${index}_${subIndex}`][0].$el.classList.add('isError')
                            } else {
                                this.$refs[`wave_range_end_${index}_${subIndex}`][0].$el.classList.remove('isError')
                            }
                        }
                        if (index == 2) {
                            if (!subItem.wave_range_start) {
                                isErrNum = 1
                                this.$refs[`age_start_${index}_${subIndex}`][0].$el.classList.add('isError')
                            } else {
                                this.$refs[`age_start_${index}_${subIndex}`][0].$el.classList.remove('isError')
                            }
                            if (!subItem.wave_range_end) {
                                isErrNum = 1
                                this.$refs[`age_end_${index}_${subIndex}`][0].$el.classList.add('isError')
                            } else {
                                this.$refs[`age_end_${index}_${subIndex}`][0].$el.classList.remove('isError')
                            }

                            if (subItem.wave_range_end <= subItem.wave_range_start) {
                                isErrAge = 1
                                this.$refs[`age_end_${index}_${subIndex}`][0].$el.classList.add('isError')
                            } else {
                                this.$refs[`age_end_${index}_${subIndex}`][0].$el.classList.remove('isError')
                            }
                        }
                    })
                    if (item.calcRatio) {
                        let obj = {
                            index: index,
                            sum: sum,
                        }
                        sumArr.push(obj)
                    }
                })
                if (isErrNum == 1) {
                    this.$message.warning('每项为必填')
                    return false
                }
                if (isErrAge == 1) {
                    this.$message.warning('年龄段最小值不能大于等于最大值')
                    return false
                }
                if (isErrOrder == 1) {
                    this.$message.warning('波动范围最小值不能大于等于最大值')
                    return false
                }
                sumArr.forEach((item, index) => {
                    if (item.sum != 100) {
                        isSum = 1
                        this.adData[item.index].list.forEach((subItem, subIndex) => {
                            this.$refs[`ratio_${item.index}_${subIndex}`][0].$el.classList.add('isError')
                        })
                    } else {
                        this.adData[item.index].list.forEach((subItem, subIndex) => {
                            this.$refs[`ratio_${item.index}_${subIndex}`][0].$el.classList.remove('isError')
                        })
                    }
                })
                if (isSum == 1) {
                    this.$message.warning('占比总和应为100')
                    return false
                }
                return true
            },
            confirmForm() {
                let res = this.checkStatus()
                if (res) {
                    localStorage.setItem('stepTwoData', JSON.stringify(this.adData))
                    this.$emit('stepActiveAdd', this.adData)
                }
            },
            delAge(index, subIndex) {
                this.adData[index].list.splice(subIndex, 1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .title-item {
        font-size: 16px;
        font-weight: 500;
        color: #060111;
        position: relative;
        padding-left: 16px;
        padding-bottom: 10px;
        border-bottom: 1px solid #EEEEEE;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            width: 6px;
            height: 12px;
            background: #2821FC;
            border-radius: 3px;
            left: 0;
            top: 5px;
        }

        .button-box {
            width: 96px;
            height: 30px;
            background: #FFFFFF;
            border: 1px solid #2821FC;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: #2821FC;
            position: absolute;
            right: 0;
            top: -10px;
            font-size: 14px;
        }
    }

    .item-input {
        &.max {
            width: 210px;
        }

        &.medium {
            width: 88px;
        }

        &.mini {
            width: 80px;
        }
    }

    ::v-deep .el-input-number .el-input__inner {
        text-align: left;
    }

    .setting-box {
        margin-top: 40px;

        &:first-child {
            margin-top: 0;
        }
    }

    .item-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 16px;
    }

    .even-content {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .odd-content {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 10px;
        position: relative;

        .odd-item {
            display: flex;
            align-items: center;
            margin-left: 40px;

            &:nth-of-type(2) {
                margin-left: 0;
            }

            .el-input-number {
                margin-left: 0;
            }
        }
    }

    .item-title {
        width: 120px;
        text-align: right;
        margin-right: 10px;
        color: #1B162A;

        &:before {
            content: '*';
            color: #ff3e6c;
            margin-right: 4px;
        }
    }

    .input-text {
        margin: 0 10px;
    }

    .divide {
        width: 15px;
        height: 2px;
        background: #D2D2D2;
        margin: 0 4px;
    }

    .el-input-number {
        margin-left: 40px;

        &:nth-of-type(2) {
            margin-left: 0;
        }
    }

    .item-column-content {
        display: flex;
        flex-wrap: wrap;

        .column-item {
            margin-left: 5px;

            &:first-child {
                margin-left: 0 !important;

                .item-content-title {
                    margin-left: 120px;
                }
            }

            .odd-item {
                margin-left: 0;
            }

            .odd-content {
                margin-top: 20px;
            }

            &:nth-of-type(6n + 1) {
                margin-left: 130px;
            }
        }
    }

    .item-content-title {
        color: #1B162A;
        margin-top: 10px;
        width: 210px;
        text-align: center;
    }

    .del-btn {
        position: absolute;
        cursor: pointer;
        color: #FF3E6C;
        left: 0;
        top: 0;
    }
</style>