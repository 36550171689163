<template>
    <div class="form-content">
        <div class="title-item">广告投放信息</div>
        <el-form :model="addForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="创建名称" prop="name">
                <el-input v-model="addForm.name" placeholder="2-18字"></el-input>
            </el-form-item>
            <el-form-item label="行业分类" prop="selectCate">
                <el-cascader v-model="addForm.selectCate" :options="categoryList" :props="categoryProps" clearable
                             style="width: 380px" @change="changeCate">
                </el-cascader>
            </el-form-item>
            <el-form-item label="脚本" prop="script_id">
                <el-select v-model="addForm.script_id" clearable placeholder="请选择脚本" style="width: 380px"
                           @change="selectScript">
                    <el-option v-for="item in scriptList" :key="item.script_id" :label="item.script_name"
                               :value="item.script_id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="money" label="推广余额">
                <el-input-number :min="100"
                                 :max="10000"
                                 :controls="false"
                                 :precision="0" v-model="addForm.money"
                                 placeholder="下限100元，上限10000元"
                                 style="width: 380px"></el-input-number>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {commodityList, advertising_getScriptList} from '@/utils/apis'

    export default {
        name: "adLaunchInfo",
        props: ['stepOneData'],
        data() {
            return {
                addForm: {
                    name: '',
                    selectCate: [],
                    category_id: '',
                    script_id: '',
                    money: void 0,
                },
                rules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                        {min: 2, max: 18, message: '长度在 2 到 18 个字符', trigger: 'blur'}
                    ],
                    selectCate: [
                        {required: true, message: '请选择行业分类', trigger: 'change'}
                    ],
                    script_id: [
                        {required: true, message: '请选择脚本', trigger: 'change'}
                    ],
                    money: [
                        {required: true, message: '请输入推广余额', trigger: 'blur'}
                    ],
                },
                categoryList: [],
                scriptList: [],
                categoryProps: {
                    value: 'category_id',
                    label: 'category_name',
                    children: 'children'
                },
                total_time: '',
            }
        },
        mounted() {
            this.promiseAll()
        },
        methods: {
            promiseAll() {
                let p1 = this.getCategoryList()
                let p2 = this.getScript()
                Promise.all([p1, p2]).then(([cate, script]) => {
                    this.categoryList = cate.data.list
                    this.scriptList = script.data
                    if (this.$route.query.id) {
                        this.addForm = this.stepOneData
                        this.selectScript(this.stepOneData.script_id)
                    } else if (localStorage.getItem('adLaunchData')) {
                        let adLaunchData = JSON.parse(localStorage.getItem('adLaunchData'))
                        this.addForm.name = adLaunchData.stepOneData.name ? adLaunchData.stepOneData.name : ''
                        this.addForm.category_id = adLaunchData.stepOneData.category_id ? adLaunchData.stepOneData.category_id : ''
                        this.addForm.script_id = adLaunchData.stepOneData.script_id ? adLaunchData.stepOneData.script_id : ''
                        this.addForm.money = adLaunchData.stepOneData.money ? adLaunchData.stepOneData.money : void 0
                        this.addForm.selectCate = adLaunchData.stepOneData.selectCate ? adLaunchData.stepOneData.selectCate : []
                        if (adLaunchData.stepOneData.script_id) {
                            this.selectScript(adLaunchData.stepOneData.script_id)
                        }
                    }
                })
            },
            getCategoryList() {
                return new Promise((resolve, reject) => {
                    let param = {
                        paging: '0'
                    }
                    commodityList(param).then(res => {
                        resolve(res)
                    }).catch((err) => {
                        reject(err)
                    })
                })
            },
            getScript() {
                return new Promise((resolve, reject) => {
                    advertising_getScriptList().then(res => {
                        resolve(res)
                    }).catch((err) => {
                        reject(err)
                    })
                })
            },
            changeCate(val) {
                let len = val.length
                if (len > 0) {
                    this.addForm.category_id = val[len - 1]
                } else {
                    this.addForm.category_id = ''
                }
            },
            getCate(id) {
                this.categoryList.forEach(item => {
                    if (item.children) {
                        item.children.forEach(subItem => {
                            if (subItem.category_id == id) {
                                this.addForm.selectCate = [item.category_id, id]
                            }
                        })
                    }
                })
            },
            selectScript(val) {
                let item = this.scriptList.find(item => {
                    return item.script_id == val
                })
                this.total_time = item.total_time
                this.$emit('getTotalTime',item.total_time)
            },
            confirmForm() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        let params = {
                            name: this.addForm.name,
                            category_id: this.addForm.category_id,
                            script_id: this.addForm.script_id,
                            money: this.addForm.money,
                            selectCate: this.addForm.selectCate,
                            total_time: this.total_time
                        }
                        this.$emit('stepActiveAdd', params)
                    } else {
                        return false;
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .title-item {
        font-size: 16px;
        font-weight: 500;
        color: #060111;
        position: relative;
        padding-left: 16px;

        &:before {
            content: '';
            position: absolute;
            width: 6px;
            height: 12px;
            background: #2821FC;
            border-radius: 3px;
            left: 0;
            top: 5px;
        }
    }

    .el-form {
        margin: 30px 0 0 60px;

        .el-input {
            width: 380px;
        }
    }

    ::v-deep .el-input-number .el-input__inner {
        text-align: left;
    }
</style>