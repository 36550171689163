<template>
    <div class="form-content">
        <div class="setting-box" v-for="(item,index) in showData" :key="`showData_${index}`">
            <div class="title-item">{{item.title | generateTitle}}</div>
            <div class="item-column-content">
                <div class="column-item" v-for="(subItem,subIndex) in item.list" :key="`settingItem_${subIndex}`">
                    <div class="odd-content" v-if="index == 0">
                        <div class="main-content">
                            <div class="item-title">{{subIndex}} ~ {{subIndex + 1}}</div>
                            <div class="odd-item">
                                <el-input class="item-input mini" disabled v-model="item.list[subIndex]"></el-input>
                                <span class="input-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="odd-content" v-if="index == 2">
                        <div class="main-content">
                            <div class="item-title">{{subItem.name}}</div>
                            <div class="odd-item">
                                <el-input class="item-input mini" disabled v-model="subItem.proportion"></el-input>
                                <span class="input-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="odd-content" v-if="index == 3">
                        <div class="main-content">
                            <div class="item-title">{{subItem.subTitle | generateGender}}</div>
                            <div class="odd-item">
                                <el-input class="item-input mini" disabled v-model="subItem.data"></el-input>
                                <span class="input-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="odd-content" v-if="index == 4">
                        <div class="main-content">
                            <div class="item-title">{{subItem.category_name}}</div>
                            <div class="odd-item">
                                <el-input class="item-input mini" disabled v-model="subItem.proportion"></el-input>
                                <span class="input-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="odd-content" v-if="index == 5">
                        <div class="main-content">
                            <div class="item-title">{{subItem.subTitle | generateNet}}</div>
                            <div class="odd-item">
                                <el-input class="item-input mini" disabled v-model="subItem.data"></el-input>
                                <span class="input-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="odd-content" v-if="index == 6">
                        <div class="main-content">
                            <div class="item-title">{{subItem.subTitle | generateDevice }}</div>
                            <div class="odd-item">
                                <el-input class="item-input mini" disabled v-model="subItem.data"></el-input>
                                <span class="input-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="odd-content" v-if="index == 7">
                        <div class="main-content">
                            <div class="item-title">{{subItem.subTitle | generateCrowd}}</div>
                            <div class="odd-item">
                                <el-input class="item-input mini" disabled v-model="subItem.data"></el-input>
                                <span class="input-text">%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-column-content" v-if="index == 1">
                <div class="row-item" v-show="!showAllCity" v-for="(subItem,subIndex) in item.list.slice(0,2)"
                     :key="`rowItem_min_${subIndex}_${subIndex}`">
                    <div class="odd-content">
                        <div class="item-content-title">{{subItem.subTitle | generateCity}}</div>
                        <div class="main-content" v-for="(endItem,endIndex) in subItem.data"
                             :key="`rowItem_min_content_${endIndex}`">
                            <div class="item-title" style="margin-right: 0">{{endItem.name}}</div>
                            <div class="odd-item">
                                <el-input class="item-input mini" disabled v-model="endItem.proportion"></el-input>
                                <span class="input-text">%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-item" v-show="showAllCity" v-for="(subItem,subIndex) in item.list"
                     :key="`rowItem_${subIndex}_${subIndex}`">
                    <div class="odd-content">
                        <div class="item-content-title">{{subItem.subTitle | generateCity}}</div>
                        <div class="main-content" v-for="(endItem,endIndex) in subItem.data"
                             :key="`rowItem_content_${endIndex}`">
                            <div class="item-title" style="margin-right: 0">{{endItem.name}}</div>
                            <div class="odd-item">
                                <el-input class="item-input mini" disabled v-model="endItem.proportion"></el-input>
                                <span class="input-text">%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="show-more" @click="showMore" v-if="index == 1">
                展开更多
                <i class="el-icon-arrow-down" v-if="!showAllCity"></i>
                <i class="el-icon-arrow-up" v-else></i>
            </div>
        </div>
    </div>
</template>

<script>
    import {advertising_getCity} from '@/utils/apis'

    export default {
        name: "generateData",
        props: ['stepThreeData'],
        data() {
            return {
                showData: [],
                cityList: [],
                showAllCity: false,
            }
        },
        filters: {
            generateTitle(type) {
                const types = {
                    'activity_time_data': '活动时间占比',
                    'region_data': '地区设置占比',
                    'age_data': '年龄设置占比',
                    'sex_data': '性别设置占比',
                    'industry_data': '行为兴趣设置占比',
                    'net_data': '网络设置占比',
                    'phone_data': '设备设置占比',
                    'crowd_data': '更多人群设置占比',
                }
                return types[type]
            },
            generateCity(type) {
                const types = {
                    'first_city': '一线城市',
                    'new_first_city': '新一线城市',
                    'second_city': '二线城市',
                    'third_city': '三线城市',
                    'fourth_city': '四线城市',
                    'fifth_city': '五线城市',
                }
                return types[type]
            },
            generateGender(type) {
                const types = {
                    'man': '男',
                    'woman': '女',
                }
                return types[type]
            },
            generateNet(type) {
                const types = {
                    'wifi': 'WIFI',
                    '2g': '2G',
                    '3g': '3G',
                    '4g': '4G',
                }
                return types[type]
            },
            generateDevice(type) {
                const types = {
                    'ios': 'ios',
                    'android': '安卓',
                }
                return types[type]
            },
            generateCrowd(type) {
                const types = {
                    "high_order": '高订单取消/退货人群占比',
                    "high_profile": '高关注度人群占比',
                    "promotion_sensitive": '促销敏感人群占比',
                    "high_active": '高活跃人群占比',
                    "tiktok_fans": '抖音号粉丝占比',
                }
                return types[type]
            },
        },
        mounted() {
            for (let i in this.stepThreeData) {
                if (i != 'unique_code' && i != 'first_proportion') {
                    let obj = {
                        title: i,
                    }
                    if (this.stepThreeData[i] instanceof Array) {
                        obj.list = this.stepThreeData[i]
                    } else {
                        let arr = []
                        for (let j in this.stepThreeData[i]) {
                            let newObj = {
                                subTitle: j,
                                data: this.stepThreeData[i][j]
                            }
                            arr.push(newObj)
                        }
                        obj.list = arr
                    }
                    this.showData.push(obj)
                }
            }
        },
        methods: {
            showMore() {
                this.showAllCity = !this.showAllCity
            }
        }
    }
</script>

<style scoped lang="scss">
    .setting-box {
        &:nth-of-type(2) .column-item {
            margin-left: 5px;

            &:nth-of-type(6n + 1) {
                margin-left: 0;
            }
        }
    }

    .title-item {
        font-size: 16px;
        font-weight: 500;
        color: #060111;
        position: relative;
        padding-left: 16px;
        padding-bottom: 10px;
        border-bottom: 1px solid #EEEEEE;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            width: 6px;
            height: 12px;
            background: #2821FC;
            border-radius: 3px;
            left: 0;
            top: 5px;
        }
    }

    .item-column-content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;

        .column-item {
            margin-left: 5px;

            &:nth-of-type(6n + 1) {
                margin-left: 70px;
            }

            .odd-item {
                margin-left: 0;
            }

            .odd-content {
                margin-top: 20px;
            }
        }

        .row-item {
            width: 100%;

            .odd-content {
                display: flex;
                flex-wrap: wrap;

                .main-content {
                    width: unset;
                    margin-top: 20px;

                    &:nth-of-type(6n + 1) {
                        margin-left: 100px;
                    }
                }
            }

            &:first-child {
                .odd-content {
                    margin-top: 0;

                    .main-content {
                        margin-top: 0;
                    }
                }
            }

            .odd-item {
                margin-left: 10px;

                .el-input {
                    margin-left: 0;
                    width: 210px;
                }
            }
        }
    }

    .odd-content {
        display: flex;
        width: 100%;
        margin-top: 10px;

        .main-content {
            display: flex;
            width: 100%;
            align-items: center;
            flex-direction: column;
        }

        .odd-item {
            display: flex;
            align-items: center;
            margin-left: 40px;

            .el-input {
                margin-left: 0;
                width: 210px;
            }
        }

        .item-title {
            width: 100%;
            text-align: center;
            margin: 0 20px 10px 0;
            color: #1B162A;
        }
    }

    .item-content-title {
        color: #1B162A;
        width: 100px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 10px;
    }

    .input-text {
        margin: 0 10px;
    }

    .divide {
        width: 15px;
        height: 2px;
        background: #D2D2D2;
        margin: 0 4px;
    }

    .el-input {
        margin-left: 40px;

        &:nth-of-type(2) {
            margin-left: 0;
        }
    }

    .show-more {
        width: 100%;
        height: 30px;
        background: #F8F8FD;
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #6762FB;
        cursor: pointer;

        i {
            margin-left: 10px;
            color: #6762FB;
        }
    }
</style>